@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-800;
  }
}
html {
    scroll-behavior: smooth;
  }

        
